<template>
  <div>
    <div class="print-header">
      <div class="print-header-content">
        <img class="print-logo" src="@/assets/print-logo.png" />
        <div class="print-header-info">
          <p>МИНИСТЕРСТВО ТРАНСПОРТА УЛЬЯНОВСКОЙ ОБЛАСТИ</p>
          <h5>ЦЕНТР ОРГАНИЗАЦИИ ДОРОЖНОГО ДВИЖЕНИЯ УЛЬЯНОВСКОЙ ОБЛАСТИ</h5>
          <p>Фруктовая ул., д.7, г.Ульяновск, 432066, тел/факс (8422) 58-30-49, E-mail: codd73@mail.ru</p>
          <p>ОКПО 00089891 ОГРН 1027301172106 ИНН/КПП 7325018444/732501001</p>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'print-header',
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.print-header {
  font-size: 12px;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid;

  .print-header-content {
    display: flex;

    .print-logo {
      width: 100px;
    }

    .print-header-info {
      padding: 5px 0 0 10px;

      p {
        margin: 0;
      }

      h5 {
        margin: 3px 0;
      }
    }
  }
}
</style>
