<template>
  <div class="print-footer">
    <div>
      <table>
          <tr>
            <td>Оператор</td>
            <td>______________ /</td>
            <td>______________ /</td>
          </tr>
          <tr>
            <td></td>
            <td align='center'>Подпись</td>
            <td align='center'>Ф.И.О.</td>
          </tr>
      </table>
      <table>
          <tr>
            <td>Начальник отдела</td>
            <td>______________ /</td>
            <td>______________ /</td>
          </tr>
          <tr>
            <td></td>
            <td align='center'>Подпись</td>
            <td align='center'>Ф.И.О.</td>
          </tr>
      </table>
    </div>
    <div class="print-date">
      <span>Дата печати: {{ currentDate.format('DD.MM.YYYY') }} г.</span>
      <span>Время печати: {{ currentDate.format('HH:mm:ss') }} ч.</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'print-footer',
  data: () => ({
    currentDate: moment(new Date()),
  }),
};
</script>

<style lang="scss" scoped>
.print-footer {
  color: gray;
  font-style: italic;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .print-date {
    font-size: 12px;

    span {
      margin: 0 10px;
    }
  }
}
</style>
