

































































































































































































/* eslint-disable camelcase */
import Vue from 'vue';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import PrintFooter from '../../../components/print/print-footer.vue';
import PrintHeader from '../../../components/print/print-header.vue';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { ACL } from '../../../modules/acl/acl-service';
import { IForm2Transport, IRouteSubtype, ITransporter } from '../../../types';
import { ModelSelect } from 'vue-search-select';
import { RouteSubtype } from '../../../services/api/route-subtype';
import { Transporter } from '../../../services/api/transporter';
import { RoadFacilitiesForm2 } from '../../../services/api/report/road-facilities/form2';
import Popup from '../../../components/shared/popup.vue';
import { ROAD_FACILITIES_TYPE_GROUP } from '../../../constants/RouteTypeGroups';
import { PROJECT_TITLE } from '@/constants/Global';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'road-facilities-form2',
  components: {
    PrintHeader,
    PrintFooter,
    DatePicker,
    ModelSelect,
    Popup,
  },
  setup(props, { root }) {
    const printing = ref(false);
    const currentDate = computed(() => moment(filter.date).format('DD.MM.YYYY'));
    const filteredTransporters = computed(() => ([
      {
        text: 'Название организации',
      },
      ...data.transporters.map(r => ({
        value: r.id,
        text: r.name,
      })),
    ]));
    const filteredRouteSubtype = computed(() => ([
      {
        text: 'Вид использования',
      },
      ...data.routeSubtypes.map(r => ({
        value: r.id,
        text: r.name,
      })),
    ]));
    const { current, isCurrentGeneral } = municipalitiesHelpers.useGetters(['current', 'isCurrentGeneral']);
    const { setCurrentMunicipality } = municipalitiesHelpers.useActions(['setCurrentMunicipality']);
    const filter: {
      date: string | Date,
      timeFrom: string | Date,
      timeTo: string | Date,
      transporter: {
        value?: number,
        text?: string,
      },
      routeSubtype: {
        value?: number,
        text?: string,
      },
    } = reactive({
      date: '',
      timeFrom: '',
      timeTo: '',
      transporter: {},
      routeSubtype: {},
    });
    const canExport = ref(false);
    const canPrint = ref(false);
    const showTrackerCrashesPopup = ref(false);
    const data: {
      value: Array<Partial<IForm2Transport>>,
      routeSubtypes: Array<IRouteSubtype>,
      transporters: Array<ITransporter>,
      sum: Partial<IForm2Transport>,
      popup: Array<{
        start_time: string,
        end_time: string,
      }>,
    } = reactive({
      value: [],
      routeSubtypes: [],
      transporters: [],
      popup: [],
      sum: {},
    });
    const headers: {
      [key in keyof Omit<IForm2Transport, 'transportId'>]: string
    } = {
      model: 'Марка, модель ТС',
      number: 'ГРЗ ТС',
      municipality: 'Место работы (название района, районов)',
      mileage: 'Фактический пробег, км',
      mileageOnTheLine: 'Фактический пробег на линии, км',
      machineHours: 'Машино-часы по факту',
      startAt: 'Время начала работы',
      endAt: 'Время окончания работы',
      route: 'Маршрут фактического прохождения',
      stopsOnTheLineCount: 'Количество стоянок на линии более 5 минут',
      trackerCrashes: 'Сбои трекеров ГЛОНАСС',
      transportStorageLocation: 'Место хранения ТС',
    };
    const sumHeaders = Object.keys(headers).filter(k => k !== 'model' && k !== 'number' && k !== 'municipality');

    const notSharedColumns = ['municipality', 'mileageOnTheLine', 'machineHours', 'startAt', 'endAt'];

    const dateParseFormat = '<\\div \\s\\t\\y\\l\\e="\\f\\o\\n\\t-\\s\\i\\z\\e: 10\\p\\x; \\c\\o\\l\\o\\r: \\g\\r\\a\\y;">DD.MM.YY</\\d\\i\\v><\\d\\i\\v>HH:mm</\\d\\i\\v>';

    const groupedData = computed(() => data.value
      .reduce((acc, current) => {
        if (!current.transportId) {
          return acc;
        }

        if (!(acc as any)[current.transportId]) {
          (acc as any)[current.transportId] = [];
        }
        (acc[current.transportId] as any).push(current);

        return acc;
      }, {} as { [key: number]: Array<Partial<IForm2Transport>> }));

    const formatDate = (date: string | null) => {
      return date ? moment.utc(date).local().format(dateParseFormat) : '—';
    };

    if (Object.keys(root.$route.query).length) {
      const routeQuery = root.$route.query;

      const date = routeQuery.date as string;
      if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        filter.date = moment(date).toDate();
      }

      const timeFrom = routeQuery.timeFrom as string;
      if (timeFrom && filter.date) {
        filter.timeFrom = moment(date + ' ' + timeFrom).toDate();
      }

      const timeTo = routeQuery.timeTo as string;
      if (timeTo && filter.date) {
        filter.timeTo = moment(date + ' ' + timeTo).toDate();
      }

      const municipalityId = routeQuery.municipalityId;
      if (municipalityId) {
        setCurrentMunicipality(Number(municipalityId));
      }

      const transporterId = routeQuery.transporterId;
      if (transporterId) {
        filter.transporter = {
          value: Number(transporterId),
        };
      }

      const routeSubtypeId = routeQuery.routeSubtypeId;
      if (routeSubtypeId) {
        filter.routeSubtype = {
          value: Number(routeSubtypeId),
        };
      }
    }

    const handleTransportForSum = (v: Array<Partial<IForm2Transport>>, isFinal: boolean = false) => {
      const result = v.reduce((acc, transport) => {
        Object.keys(acc)
          .forEach((key) => {
            const element = transport[key as keyof IForm2Transport];
            if (element) {
              switch (key) {
                case 'startAt':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = element as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(element as string, dateParseFormat);

                    if (newTime.isBefore(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'endAt':
                  if (!(acc as any)[key] || (acc as any)[key] === '—') {
                    (acc as any)[key] = element as string;
                  } else {
                    const oldTime = moment((acc as any)[key] as string, dateParseFormat);
                    const newTime = moment(element as string, dateParseFormat);

                    if (newTime.isAfter(oldTime)) {
                      (acc as any)[key] = newTime.format(dateParseFormat);
                    }
                  }
                  break;
                case 'trackerCrashes':
                  if (Array.isArray(element)) {
                    (acc as any)[key] += element.length;
                  } else if (isFinal) {
                    (acc as any)[key] += element;
                  }
                  break;
                default:
                  (acc as any)[key] += Number(element);
                  break;
              }
            }
          });

        return acc;
      }, {
        mileage: 0,
        mileageOnTheLine: 0,
        machineHours: 0,
        startAt: '',
        endAt: '',
        stopsOnTheLineCount: 0,
        trackerCrashes: 0,
      });

      if (isFinal) {
        result.machineHours = Number(result.machineHours).toFixed(1);
        if (result.mileage) {
          result.mileage = Math.floor(result.mileage);
        }
        if (result.mileageOnTheLine) {
          result.mileageOnTheLine = Math.floor(result.mileageOnTheLine);
        }
      }

      return result;
    };

    const getSum = (data: {
        [key: number]: Array<Partial<IForm2Transport>>;
    }) => {
      const buffer = Object.entries(data).map(([k, v]) => handleTransportForSum(v));

      return handleTransportForSum(buffer, true);
    };

    const getData = () => {
      if (!filter.date || !filter.transporter.value) {
        return;
      }

      const query = {
        municipalityId: current.value.id !== 0 ? current.value.id : undefined,
        transporterId: filter.transporter.value,
        routeSubtypeId: filter.routeSubtype.value,
        date: moment(filter.date).format('YYYY-MM-DD'),
        timeFrom: filter.timeFrom ? moment(filter.timeFrom).format('HH:mm') : '',
        timeTo: filter.timeTo ? moment(filter.timeTo).format('HH:mm') : '',
      };

      if (root.$route.query.transporterId?.toString() !== query.transporterId?.toString() ||
        root.$route.query.municipalityId?.toString() !== query.municipalityId?.toString() ||
        root.$route.query.date !== query.date ||
        root.$route.query.timeFrom !== query.timeFrom ||
        root.$route.query.timeTo !== query.timeTo ||
        root.$route.query.routeSubtypeId?.toString() !== query.routeSubtypeId?.toString()) {
        root.$router.replace({
          query: query as any,
        }).catch(() => {});
      }

      RoadFacilitiesForm2.getData(query)
        .then((response: Array<IForm2Transport>) => {
          data.value = [...response.map(t => ({
            ...t,
            startAt: formatDate(t.startAt),
            endAt: formatDate(t.endAt),
            model: t.model ? t.model : '—',
            machineHours: Number(t.machineHours).toFixed(1),
            route: 'Показать маршрут',
          }))];

          data.sum = getSum(groupedData.value);

          canExport.value = ACL.can('report_export.form2');
          canPrint.value = true;
        });
    };

    const init = () => {
      RouteSubtype.getAll()
        .then((routeSubtypes: Array<IRouteSubtype>) => {
          data.routeSubtypes = [...routeSubtypes];

          if (filter.routeSubtype.value) {
            filter.routeSubtype.text = data.routeSubtypes.find(r => Number(r.id) === Number(filter.routeSubtype.value))?.name;
          }
        });

      Transporter.getTransporters({
        all: 1,
        filter: {
          municipality: current.value.id !== 0 ? current.value.id : undefined,
          typeGroup: ROAD_FACILITIES_TYPE_GROUP,
        },
      })
        .then((response: {
          transporters: Array<ITransporter>
        }) => {
          data.transporters = [...response.transporters];

          if (filter.transporter.value) {
            filter.transporter.text = data.transporters.find(t => Number(t.id) === Number(filter.transporter.value))?.name;
          }
        });

      getData();
    };
    init();

    const historyDateParams = computed(() => {
      const date = moment(filter.date);
      const dateFromObject = moment(date.startOf('day'));
      const dateToObject = moment(date.endOf('day'));
      let dateFrom = dateFromObject.format('YYYY-MM-DD HH:mm:ss');
      let dateTo = dateToObject.format('YYYY-MM-DD HH:mm:ss');

      if (filter.timeTo && filter.timeFrom) {
        const timeTo = moment(date.format('YYYY-MM-DD') + ' ' + moment(filter.timeTo).format('HH:mm:ss'));
        const timeFrom = moment(date.format('YYYY-MM-DD') + ' ' + moment(filter.timeFrom).format('HH:mm:ss'));

        if (timeFrom.isSameOrAfter(timeTo)) {
          dateToObject.add(1, 'days');
        }

        dateFrom = `${dateFromObject.format('YYYY-MM-DD')} ${timeFrom.format('HH:mm:ss')}`;
        dateTo = `${dateToObject.format('YYYY-MM-DD')} ${timeTo.format('HH:mm:ss')}`;
      } else if (filter.timeTo) {
        const timeTo = moment(filter.timeTo);
        dateTo = `${dateToObject.format('YYYY-MM-DD')} ${timeTo.format('HH:mm:ss')}`;
      } else if (filter.timeFrom) {
        const timeFrom = moment(filter.timeFrom);
        dateFrom = `${dateFromObject.format('YYYY-MM-DD')} ${timeFrom.format('HH:mm:ss')}`;
      }

      return {
        dateTo,
        dateFrom,
        dateToFormatted: moment(dateTo).format('DD.MM.YYYY HH:mm'),
        dateFromFormatted: moment(dateFrom).format('DD.MM.YYYY HH:mm'),
      };
    });

    watch(current, () => init());
    watch(filter, () => getData());

    return {
      groupedData,
      data,
      headers,
      sumHeaders,
      filter,
      printing,
      currentDate,
      current,
      isCurrentGeneral,
      canExport,
      canPrint,
      notSharedColumns,
      filteredTransporters,
      filteredRouteSubtype,
      showTrackerCrashesPopup,
      historyDateParams,
      disabledAfter(date: string) {
        const newDate = moment(date);
        const maxDate = moment(new Date()).add(-1, 'days');

        return !newDate.isSameOrBefore(maxDate);
      },
      changeTransporter() {

      },
      loadReport() {

      },
      exportReport() {

      },
      printReport() {
        const header: HTMLElement | null = document.querySelector('header.header');
        const content: HTMLElement | null = document.querySelector('.content');
        const report: HTMLElement | null = document.querySelector('#print-report');
        const sticky: NodeListOf<HTMLElement> = document.querySelectorAll('.table th');

        if (!header || !content || !report) {
          return;
        }

        header.hidden = true;
        content.style.margin = '0';
        report.style.overflow = 'visible';
        printing.value = true;
        document.title = `Отчет Форма № 2 │ ${moment(filter.date).format('DD.MM.YYYY')}`;
        sticky.forEach(e => {
          e.style.position = 'static';
          e.style.borderTop = '1px solid #dee2e6';
        });

        setTimeout(() => {
          window.print();

          header.hidden = false;
          content.style.margin = '';
          report.style.overflow = '';
          printing.value = false;
          sticky.forEach(e => {
            e.style.position = 'sticky';
            e.style.borderTop = 'none';
          });
          document.title = PROJECT_TITLE;
        }, 1000);
      },
      getForm3Query(transport: IForm2Transport) {
        const transporterId = filter.transporter.value;
        const transportId = transport.transportId;
        const date = moment(filter.date).format('YYYY-MM-DD');
        const municipalityId = current.value.id;

        return {
          transporterId,
          transportId,
          municipalityId,
          date,
          timeFrom: filter.timeFrom ? moment(filter.timeFrom).format('HH:mm') : '',
          timeTo: filter.timeTo ? moment(filter.timeTo).format('HH:mm') : '',
        };
      },
      getHistoryParams(transport: IForm2Transport) {
        const transporterId = filter.transporter.value;
        const transportId = transport.transportId;
        const { dateTo, dateFrom } = historyDateParams.value;

        return {
          transporterId,
          transportId,
          dateFrom,
          dateTo,
        };
      },
      openTrackerCrashesPopup(trackerCrashes: Array<{
          crashe_time: string,
          end_time: string,
          start_time: string,
        }>) {
        data.popup = trackerCrashes.map(tc => {
          return {
            start_time: moment.utc(tc.start_time).local().format('HH:mm'),
            end_time: moment.utc(tc.end_time).local().format('HH:mm'),
          };
        });
        showTrackerCrashesPopup.value = true;
      },
      getTrackerCrashes(key: number) {
        return groupedData.value[key].reduce((acc: Array<{
            crashe_time: string;
            end_time: string;
            start_time: string;
        }>, val: Partial<IForm2Transport>) => {
          acc.push(...(val.trackerCrashes as Array<{
            crashe_time: string;
            end_time: string;
            start_time: string;
          }>));

          return acc;
        }, []);
      },
    };
  },
});
