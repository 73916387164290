import axios from '@/services/axios';
import { IForm2Transport } from '@/types';
import { AxiosResponse } from 'axios';

export class RoadFacilitiesForm2 {
  static getData({ municipalityId, date, transporterId, routeSubtypeId, timeFrom, timeTo }: {
    municipalityId?: number,
    date: string,
    transporterId?: number,
    routeSubtypeId?: number,
    timeFrom?: string,
    timeTo?: string,
  }) {
    return axios.get('/report-road-machinery/form2', {
      params: {
        municipality_id: municipalityId,
        transporter_id: transporterId,
        route_subtype_id: routeSubtypeId,
        time_from: timeFrom,
        time_to: timeTo,
        date,
      },
    })
      .then((response: AxiosResponse<{
        transports: Array<IForm2Transport>
      }>) => response.data.transports);
  }
}
